( function ( _, $, Flickity ) {
    "use strict";

    var $window = $( window ),
        $body = $( "body" ),

        // Create a proxy element for tracking the offset of the layout viewport.
        //
        // Needed for figuring out the scroll coordinates of the (0,0) origin for `position: fixed` - in other words,
        // the scroll coordinates of the layout viewport. The proxy element is added to the DOM when it is needed for
        // the first time (and stays on forever after).
        //
        // NB There doesn't seem to be a better way to track this. See
        // https://github.com/WICG/visual-viewport/issues/16#issuecomment-223631627
        $layoutViewportProxy = $( document.createElement( "div" ) )
            .addClass( "layout-viewport-proxy" )        // just for labelling the element, no styles attached to the class
            .css( {
                position: "fixed",
                zIndex: -1000000,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                margin: 0,
                padding: 0,
                border: "none",
                display: "block"
            } ),

        /**
         * Creates a content carousel.
         *
         * Scope and container
         * -------------------
         *
         * The carousel requires an outer and an inner container element around it.
         *
         * - The "carousel container" is the inner one of the two. It is auto-detected if a `.carousel-container` class
         *   is set on it. It should contain the carousel and nothing else. A companion title carousel may also be
         *   placed inside it (but it can also be placed elsewhere).
         *
         * - The "scope" is the outer container. It is usually set to the entire gallery block, which can be placed on
         *   arbitrary pages as an independent module. The scope must never contain more than a single content carousel.
         *   As long as that is guaranteed, it can be set as far up the hierarchy as the `body` or even the `html`
         *   element (= d.dE ) - but NOT the document itself. The scope should contain all components on the page which
         *   respond to the gallery in some way, e.g. to the `.is-dark` or `.is-light` classes describing the current
         *   image (see below).
         *
         * - Whenever the carousel enters full-screen mode, a `.carousel-fullscreen` class is set on the scope and the
         *   carousel container. (The carousel element itself is handled by Flickity and gets an `.is-fullscreen` class).
         *
         * - If is-dark/is-light classes are enabled (`enableLightnessClasses()` method) and the carousel shows an image
         *   flagged as dark, an `.is-dark` class is set on the scope and the carousel container. Otherwise, an
         *  `.is-light` class is set. Elements inside the scope can adjust their styling in response to these classes.
         *
         * Other features
         * --------------
         *
         * - Enables clicks to navigate the gallery as specified in the `data-navigate-by-click` attribute of the
         *   carousel container. Supported attribute values are "all" (enabled for all breakpoints), "small-portrait"
         *   (enabled for small screens in portrait orientation), or "none" (disabled).
         *
         * - To enable `.is-dark` and `.is-light` classes on the scope and carousel container elements, call the method
         *   `enableLightnessClasses()`. The classes reflect whether or not the current image in the carousel is flagged
         *   as dark.
         *
         * @param {jQuery} $scope                the top-level element of the entire block related to this gallery, ie
         *                                       the outermost element which separates the content carousel from
         *                                       (potential) other content carousels on the same page. You can use body
         *                                       or html/d.dE if there won't ever be more than one content carousel.
         * @param {Object} [options]             supports all flickity options, and the ones listed below
         * @param {jQuery} [options.$container]  the container element, if used. The default container
         *                                       (`.carousel-container` parent) is auto-detected, no need to pass it in
         *                                       explicitly.
         * @constructor
         */
        ContentCarousel = function ( $scope, options ) {
            var navigateByClick,
                self = this;

            _.bindAll( this, "enableLightnessClasses", "enableNavigationByClick", "disableNavigationByClick",
                "_enableConditionalNavigationByClick", "_onStaticClick", "_setLightnessClass",
                "_fullscreenWatchWindowResize", "_preventBouncingWhileSwiping"
            );

            options = _.extend( {
                setGallerySize: false,
                wrapAround: true,
                imagesLoaded: true,
                cellSelector: ".carousel-cell"
            }, options || {} );

            this.$scope = $scope;
            this.$elem = $( ".content-carousel", options.$container || $scope ).flickity( options );
            this.flickity = this.$elem.data( "flickity" );

            // Auto-detect the carousel container if there is one (and it hasn't been passed in explicitly)
            this.$container = options.$container || this.$elem.parent( ".carousel-container" );

            if ( options.fullscreen ) {
                this.$elem.on( "fullscreenChange.flickity", function ( event, isFullscreen ) {
                    var action = isFullscreen ? "addClass" : "removeClass";

                    self.$container[action]( "carousel-fullscreen" );
                    self.$scope[action]( "carousel-fullscreen" );

                    self._fullscreenWatchWindowResize( isFullscreen );
                } );
            }

            navigateByClick = this.$container.data( "navigate-by-click" );
            if ( navigateByClick === "all" ) {
                this.enableNavigationByClick();
            } else if ( navigateByClick === "small-portrait" ) {
                this._enableConditionalNavigationByClick();
            }

            this._preventBouncingWhileSwiping();
        },

        /**
         * Creates a content carousel.
         *
         * @param {Object} [options]             supports all flickity options, and the ones listed below
         * @param {jQuery} [options.$container]  the container element, if used. The default container
         *                                       (`.carousel-container` parent) is auto-detected, no need to pass it in
         *                                       explicitly. Unless you set options.asNavFor, the container element must
         *                                       contain both the title carousel and the content carousel it is supposed
         *                                       to control.
         * @constructor
         */
        TitleCarousel = function ( options ) {
            var self = this;

            this.$elem = $( ".title-carousel", options && options.$container || document );

            // Auto-detect the carousel container if there is one (and it hasn't been passed in explicitly)
            this.$container = options && options.$container || this.$elem.parent( ".carousel-container" );

            options = _.extend( {
                setGallerySize: false,
                wrapAround: true,
                cellSelector: ".carousel-cell",
                pageDots: false,
                prevNextButtons: false,
                draggable: false,
                asNavFor: $( ".content-carousel", this.$container )[0]
            }, options || {} );

            this.$elem.flickity( options );
            this.flickity = this.$elem.data( "flickity" );

            this._lastViewedTitleElement = this.flickity.selectedElement;

            this.$elem
                .on( 'select.flickity', function () {
                    // In case a new selection is made before the previous item has settled, the `settle` event doesn't
                    // fire for the previous selection. Make sure the previous, fading element is restored to its normal
                    // state.
                    if ( self._fadingTitleElement ) self._fadingTitleElement.classList.remove( "fade" );

                    self._fadingTitleElement = self._lastViewedTitleElement;
                    self._fadingTitleElement.classList.add( "fade" );
                    self._lastViewedTitleElement = self.flickity.selectedElement;
                } )
                .on( 'settle.flickity', function () {
                    self._fadingTitleElement.classList.remove( "fade" );
                    self._fadingTitleElement = false;
                } );
        };


    _.extend( ContentCarousel.prototype, {

        enableLightnessClasses: function () {
            var self = this;

            this.$elem.on( 'select.flickity', function () {
                self._setLightnessClass();
            } );

            this._setLightnessClass();

            return this;
        },

        enableNavigationByClick: function () {
            this.$elem.on( "staticClick.flickity", this._onStaticClick );
        },

        disableNavigationByClick: function () {
            this.$elem.off( "staticClick.flickity", this._onStaticClick );
        },

        _enableConditionalNavigationByClick: function () {
            var self = this,

                update = function () {
                    if ( window.matchMedia( "(orientation: portrait)" ).matches && !Foundation.MediaQuery.is( "medium" ) ) {
                        self.enableNavigationByClick();
                    } else {
                        self.disableNavigationByClick();
                    }
                };

            $window.resize( _.debounce( function () {
                update();
            }, 100 ) );

            // Run immediately to activate
            update();
        },

        _onStaticClick: function ( event, pointer, cellElement ) {
            var layoutViewportDims, carouselCellDims, cellCenterX;

            if ( !cellElement ) return;
            ensureViewportProxy();

            layoutViewportDims = $layoutViewportProxy[0].getBoundingClientRect();
            carouselCellDims = cellElement.getBoundingClientRect();
            cellCenterX = layoutViewportDims.left + carouselCellDims.left + (carouselCellDims.width / 2);

            if ( pointer.pageX >= cellCenterX ) {
                this.$elem.flickity( "next" );
            } else {
                this.$elem.flickity( "previous" );
            }
        },

        _setLightnessClass: function () {
            var isDark = $( this.flickity.selectedElement ).data( "image-is-dark" );

            if ( isDark ) {
                this.$scope.removeClass( "is-light" ).addClass( "is-dark" );
                this.$container.removeClass( "is-light" ).addClass( "is-dark" );
            } else {
                this.$scope.addClass( "is-light" ).removeClass( "is-dark" );
                this.$container.addClass( "is-light" ).removeClass( "is-dark" );
            }
        },

        _fullscreenWatchWindowResize: function ( isFullscreen ) {
            var self = this;

            ensureViewportProxy();

            if ( !this.$pageDots ) this.$pageDots = $( ".flickity-page-dots" );
            if ( !this.$fullscreenExitButton ) this.$fullscreenExitButton = $( ".flickity-fullscreen-button-exit" );

            if ( !this._fullscreenOnResize ) this._fullscreenOnResize = _.debounce( function () {
                var dotHeight = self.$pageDots[0].getBoundingClientRect().height,
                    buttonHeight = self.$fullscreenExitButton[0].getBoundingClientRect().height,

                    carouselBottom = self.$elem[0].getBoundingClientRect().bottom,
                    layoutViewportBottom = $layoutViewportProxy[0].getBoundingClientRect().bottom,

                    requiredHeight = Math.max( 1.5 * dotHeight, 1.25 * buttonHeight ),
                    availableHeight = layoutViewportBottom - carouselBottom,

                    action = availableHeight < requiredHeight ? "removeClass" : "addClass";

                self.$elem[action]( "controls-below" );
            }, 40 );

            if ( isFullscreen ) {
                $window.resize( this._fullscreenOnResize );
                // Run the handler immediately, too.
                this._fullscreenOnResize();
            } else {
                $window.off( "resize", this._fullscreenOnResize );
            }
        },

        /**
         * Prevents bouncing and bobbing in iOS while dragging is in process.
         *
         * Does not interfere with vertical scrolling by touch, even if it is initiated on the carousel. Only while the
         * actual dragging is in process, scrolling is suppressed.
         *
         * The bouncing is described in Flickity issue #740 (https://github.com/metafizzy/flickity/issues/740), where
         * this fix was suggested in the comments. Apparently, iOS 12 will fix the underlying issue.
         *
         * So the method **might** become obsolete in iOS 12. If so, remove it - adding an event handler on each drag,
         * as it happens here, can cause a short delay. However, verify first that the bouncing is eliminated fully by
         * the iOS fix.
         */
        _preventBouncingWhileSwiping: function () {
            this.$elem.on( "dragStart.flickity", function () {
                document.ontouchmove = function ( event ) {
                    event.preventDefault();
                }
            } );

            this.$elem.on( "dragEnd.flickity", function () {
                document.ontouchmove = function () {
                    return true;
                }
            } );
        }

    } );


    /**
     * Inserts the proxy element for querying the position of the layout viewport, if not already done. The proxy then
     * stays in the DOM forever.
     *
     * NB The layout viewport is the viewport which `position: fixed` refers to.
     */
    function ensureViewportProxy () {
        if ( !$.contains( document.body, $layoutViewportProxy[0] ) ) $layoutViewportProxy.appendTo( "body" );
    }


    /**
     * Export global objects
     */
    window.ContentCarousel = ContentCarousel;
    window.TitleCarousel = TitleCarousel;

} )( _, jQuery, Flickity );